import { defineStore } from 'pinia';

import {
    coachHistoryUrl,
    activityListUrl,
    activityListNoCreditUrl,
    activityMessageAnalyticsUrl,
    activityToDoAnalyticsUrl,
    activityExamPlaybookAnalyticsUrl,
} from '../urls.js';

import { genericMixin } from '../stores/generic.js';

const coachHistoryInfo = coachHistoryUrl.match(location.pathname);
const coachId = coachHistoryInfo ? Number(coachHistoryInfo.coachId) : null;

const initialState = {
  coachId,
  userCredits: [],
  userActivity: [],
  bulletinActivityHistory: [],
  toDoActivityHistory: [],
  playbookActivityHistory: [],
}

export const useStudentActivityStore = defineStore('activity-history', {
  state: () => initialState,
  getters: {
    ...genericMixin.getters,
  },
  actions: {
    ...genericMixin.actions,
    setUserCreditList ({ data }) {
      this.userCredits = data;
    },
    setUserActivityList ({ data }) {
      this.userActivity = data;
    },
    setActivityMessageAnalytics ({ data }) {
      this.bulletinActivityHistory = data;
    },
    setActivityToDoAnalytics ({ data }) {
      this.toDoActivityHistory = data;
    },
    setActivityExamPlaybookAnalytics ({ data }) {
      this.playbookActivityHistory = data;
    },
    getUserCreditList () {
      return this.loadObjectList({
        mutation: this.setUserCreditList,
        urls: { list: activityListUrl },
        urlParams: { coachId: this.coachId }
      });
    },
    getUserActivityList () {
      return this.loadObjectList({
        mutation: this.setUserActivityList,
        urls: { list: activityListNoCreditUrl },
        urlParams: { coachId: this.coachId }
      });
    },
    getActivityMessageAnalytics () {
      return this.loadObjectList({
        mutation: this.setActivityMessageAnalytics,
        urls: { list: activityMessageAnalyticsUrl },
        urlParams: {
          coachId: this.coachId
        }
      });
    },
    getActivityToDoAnalytics () {
      return this.loadObjectList({
        mutation: this.setActivityToDoAnalytics,
        urls: { list: activityToDoAnalyticsUrl },
        urlParams: { coachId: this.coachId }
      });
    },
    getActivityExamPlaybookAnalytics () {
      return this.loadObjectList({
        mutation: this.setActivityExamPlaybookAnalytics,
        urls: { list: activityExamPlaybookAnalyticsUrl },
        urlParams: { coachId: this.coachId }
      });
    },
  },
});
