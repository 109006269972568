<template>
    <ul class="tab-menu">
        <li class="bulletin-tab tab" :class="{active: recommendTabActive}">
            <button type="button"
                class="btn tab-btn"
                data-tab-type="recommended"
                aria-selected="true"
                aria-controls="recommendedList"
                role="tab"
                id="recommendedTab"
                @click="setActiveList( recommendTab )"
            >
                Recommended
            </button>
        </li>
        <li class="bulletin-tab tab" :class="{active: starTabActive}">
            <button type="button"
                class="btn tab-btn"
                data-tab-type="starred"
                aria-selected="false"
                aria-controls="starredList"
                role="tab"
                id="starredTab"
                @click="setActiveList( starredTab )"
            >
                Starred
            </button>
        </li>
        <li class="bulletin-tab tab" :class="{active: allTabActive}">
            <button type="button"
                class="btn tab-btn"
                data-tab-type="all"
                aria-selected="false"
                aria-controls="allList"
                role="tab"
                id="allTab"
                @click="setActiveList( allTab )"
            >
                All
            </button>
        </li>
    </ul>
    <div class="tabbed-bulletin-list" id="tabbedBulletinList">
        <div class="tab-list" data-tab-list="recommended" aria-labelledby="recommendedTab" id="recommendedList" :class="{active: recommendTabActive}">
            <bulletin-list-card
                v-for="bulletin in recommendedBulletins"
                :key="`rec-${bulletin.id}`"
                :bulletin="bulletin"
                :starred-state="bulletin.starred"
            />
        </div>
        <div class="tab-list" data-tab-list="starred" aria-labelledby="starredTab" id="starredList" :class="{active: starTabActive}">
            <bulletin-list-card
                v-for="bulletin in starredBulletins"
                :key="`star-${bulletin.id}`"
                :bulletin="bulletin"
                :starred-state="bulletin.starred"
            />
            <div class="card card-empty" v-if="!starredBulletins.length">
                <div class="icon-container">
                    <svg class="empty-icon" role="presentation">
                        <use xlink:href="/static/img/IconSprite.svg#starred"></use>
                    </svg>
                </div>
                <p>You haven’t starred any Bulletins.</p>
            </div>
        </div>
        <div class="tab-list" data-tab-list="all" aria-labelledby="allTab" id="allList" :class="{active: allTabActive}">
            <bulletin-list-card
                v-for="bulletin in allBulletins"
                :key="`all-${bulletin.id}`"
                :bulletin="bulletin"
                :starred-state="bulletin.starred"
            />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useBulletinStore } from './bulletin.js';

import { loadingMixin } from "../../mixins/loading.js";
import BulletinListCard from "./BulletinListCard.vue";


export default {
  name: 'BulletinList',
  mixins: [
    loadingMixin,
  ],
  components: {
    BulletinListCard,
  },
  data () {
    return {
      recommendTab: "recommendedTab",
      starredTab: "starredTab",
      allTab: "allTab",
      activeList: "recommendedTab"
    };
  },
  computed: {
    ...mapState(useBulletinStore, [
      'bulletinList',
      'recommendedBulletins',
      'starredBulletins',
      'allBulletins',
    ]),
    displayedBulletins () {
      if (this.recommendTabActive) {
        return this.recommendedBulletins;
      } else if (this.starTabActive) {
        return this.starredBulletins;
      } else {
        return this.allBulletins;
      }
    },
    recommendTabActive () {
      return this.activeList === this.recommendTab;
    },
    starTabActive () {
      return this.activeList === this.starredTab;
    },
    allTabActive () {
      return this.activeList === this.allTab;
    },
  },
  methods: {
    ...mapActions(useBulletinStore, [
      'loadBulletinList',
    ]),
    setActiveList (activeTab) {
      this.activeList = activeTab;
    },
    resetInterface() {
      this.loadInterfaceData();
    },
    loadInterfaceData() {
      this.startLoading();
      Promise.allSettled([
        this.loadBulletinList(),
      ]).then(() => {
        this.stopLoading();
      });
    },
  },
  created () {
    this.loadInterfaceData();
  },
}
</script>
